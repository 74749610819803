import React from 'react';
import { Paper, Typography, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';

const Home = () => {
  const fadeIn = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: 500 });

  const hoverAnimation = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.05)' },
    config: { tension: 300, friction: 10 },
  });

  return (
    <Container maxWidth="sm" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <animated.div style={{ position: 'absolute', top: 20, left: 20, ...fadeIn }}>
        <Typography variant="h6">
          すごい円周率
        </Typography>
      </animated.div>

      <animated.div style={{ ...fadeIn, flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}> {/* カードのサイズを元に戻す */}
            <Link to="/timeattack" style={{ textDecoration: 'none' }}>
              <animated.div style={{ ...hoverAnimation, width: '100%', height: '100%' }}>
                <Paper elevation={3} style={{ padding: '40px', textAlign: 'center', height: '100%' }}>
                  <Typography variant="h4" gutterBottom>
                    タイムアタック
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    円周率をできるだけ早く打ち込んで、タイムを競い合いましょう！
                  </Typography>
                </Paper>
              </animated.div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={6}> {/* カードのサイズを元に戻す */}
            <Link to="/practice" style={{ textDecoration: 'none' }}>
              <animated.div style={{ ...hoverAnimation, width: '100%', height: '100%' }}>
                <Paper elevation={3} style={{ padding: '40px', textAlign: 'center', height: '100%' }}>
                  <Typography variant="h4" gutterBottom>
                    れんしゅう
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    その名の通りれんしゅうできます!
                  </Typography>
                </Paper>
              </animated.div>
            </Link>
          </Grid>
        </Grid>
      </animated.div>

      <animated.div style={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)', ...fadeIn }}>
        <Typography variant="body2" color="textSecondary">
          Powered by にゃーんねっと
        </Typography>
      </animated.div>
    </Container>
  );
};

export default Home;
