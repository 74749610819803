import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, TextField, Button } from '@mui/material';

const Practice = () => {
  const [input, setInput] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [timeTaken, setTimeTaken] = useState(null);
  const [timer, setTimer] = useState(0);
  const [error, setError] = useState(false);
  const pi = '3.14159265358979323846264338327950288419716939937510';

  useEffect(() => {
    let interval;
    if (startTime) {
      interval = setInterval(() => {
        setTimer(((Date.now() - startTime) / 1000).toFixed(2));
      }, 100);
    } else {
      clearInterval(interval);
      setTimer(0);
    }
    return () => clearInterval(interval);
  }, [startTime]);

  const handleChange = (e) => {
    const value = e.target.value;
    if (!startTime) {
      setStartTime(Date.now());
    }
    if (value === pi.slice(0, value.length)) {
      setError(false);
      setInput(value);
      if (value === pi) {
        const endTime = Date.now();
        const timeTaken = (endTime - startTime) / 1000;
        setTimeTaken(timeTaken);
        setStartTime(null);
        setInput('');
      }
    } else {
      setError(true);
    }
  };

  const handleRetry = () => {
    setInput('');
    setStartTime(null);
    setTimeTaken(null);
    setError(false);
  };

  const renderPi = () => {
    return pi.split('').map((char, index) => (
      <span key={index} style={{ color: input[index] === char ? 'yellowgreen' : 'black' }}>
        {char}
      </span>
    ));
  };

  return (
    <Container maxWidth="sm" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '20px' }}>
      <div style={{ position: 'absolute', top: 20, left: 20 }}>
        <Typography variant="h6">
          すごい円周率-れんしゅう
        </Typography>
      </div>

      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h5" style={{ margin: '20px 0', wordBreak: 'break-all', textAlign: 'center' }}>
          {renderPi()}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          value={input}
          onChange={handleChange}
          placeholder="円周率を入力してください..."
          style={{ width: '100%' }}
          inputProps={{ style: { fontSize: '2rem' }, spellCheck: 'false', autoComplete: 'off' }}
          error={error}
          helperText={error ? '間違っています。もう一度試してください。' : ''}
        />
        <Typography variant="h6" style={{ fontSize: '2rem', marginTop: '20px' }}>
          タイマー: {timer} 秒
        </Typography>
        {timeTaken && <Typography variant="h6" style={{ fontSize: '2rem' }}>かかった時間: {timeTaken} 秒</Typography>}
        {timeTaken && <Button variant="contained" color="primary" onClick={handleRetry} style={{ marginTop: '20px' }}>リトライ</Button>}
      </div>

      <Box style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant="body2" color="textSecondary">
          Powered by にゃーんねっと
        </Typography>
      </Box>
    </Container>
  );
};

export default Practice;
