import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, TextField, Button } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';

const TimeAttack = () => {
  const [input, setInput] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [timeTaken, setTimeTaken] = useState(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (startTime) {
      interval = setInterval(() => {
        setTimer(((Date.now() - startTime) / 1000).toFixed(2));
      }, 100);
    } else {
      clearInterval(interval);
      setTimer(0);
    }
    return () => clearInterval(interval);
  }, [startTime]);

  const handleChange = (e) => {
    if (!startTime) {
      setStartTime(Date.now());
    }
    setInput(e.target.value);
    const pi = '3.14159265358979323846264338327950288419716939937510';
    if (e.target.value === pi.slice(0, e.target.value.length)) {
      if (e.target.value === pi) {
        const endTime = Date.now();
        const timeTaken = (endTime - startTime) / 1000;
        setTimeTaken(timeTaken);
        setStartTime(null);
        setInput('');
      }
    } else {
      setStartTime(null);
      setInput('');
    }
  };

  const handleRetry = () => {
    setInput('');
    setStartTime(null);
    setTimeTaken(null);
  };

  const fadeIn = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: 500 });

  return (
    <Container maxWidth="sm" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <animated.div style={{ position: 'absolute', top: 20, left: 20, ...fadeIn }}>
        <Typography variant="h6">
          すごい円周率
        </Typography>
      </animated.div>

      <animated.div style={{ ...fadeIn, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h4">Pi Typing Time Attack</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          value={input}
          onChange={handleChange}
          placeholder="Type pi..."
          style={{ width: '100%' }}
          inputProps={{ style: { fontSize: '2rem' }, spellCheck: 'false', autoComplete: 'off' }}
        />
        <Typography variant="h6" style={{ fontSize: '2rem' }}>
          Timer: {timer} seconds
        </Typography>
        {timeTaken && <Typography variant="h6" style={{ fontSize: '2rem' }}>Time taken: {timeTaken} seconds</Typography>}
        {timeTaken && <Button variant="contained" color="primary" onClick={handleRetry} style={{ marginTop: '20px' }}>Retry</Button>}
      </animated.div>

      <Box style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant="body2" color="textSecondary">
          Powered by にゃーんねっと
        </Typography>
      </Box>
    </Container>
  );
};

export default TimeAttack;
